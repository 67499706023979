export enum CreatePadNameType {
  RandomChars = "RandomChars",
  RandomWords = "RandomWords",
  UserDefined = "UserDefined"
}

export interface CreatePadRequest {
  name_type: CreatePadNameType
  expiration_in_minutes: number
  password?: string
  requested_name?: string
}

export interface CreatePadResponse {
  name: string
}
