import React, { useState, useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';

export const PasswordBox = (props: {onSubmit: (password: string) => void}) => {
  const isMountedRef = useRef(true)
  const [password, setPassword] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handlePasswordKeyPress = (event: React.KeyboardEvent<any>) => {
    if (event.key === 'Enter') {
      handlePasswordSubmit()
    }
  }

  const handlePasswordSubmit = () => {
    setIsSubmitting(true)
    props.onSubmit(password)
    if (isMountedRef.current) {
      setIsSubmitting(false)
    }
  }

  // When unmounted, set to unmounted.
  useEffect(() => () => {isMountedRef.current = false}, [])

  return (
    <PasswordContainer>
      <FormGroup>
        <TextField
          margin="dense"
          type="password"
          label="Password"
          autoFocus
          variant="outlined"
          disabled={isSubmitting}
          onKeyPress={handlePasswordKeyPress}
          onChange={handlePasswordChange}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={handlePasswordSubmit}
        >
          Access
        </Button>
      </FormGroup>
    </PasswordContainer>
  )
}

const PasswordContainer = styled(Container)`
  max-width: 200px;
  margin-bottom: 20px;
`;
