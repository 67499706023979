export interface GetPadResponse {
  expiration_time: Date
  memos: Memo[]
  error_code: number
  error_message: string
}

export interface Pad {
  expiration_time: Date
  memos: Memo[]
}

export interface Memo {
  timestamp: Date
  memo_type: MemoType
  ip_address: string
  location: string
  message?: string
  file?: File
}

export enum MemoType {
  Message = "Message",
  File = "File"
}

export interface File {
  name: string
  size: number,
  link: string
}
