import { CreatePadRequest, CreatePadNameType } from '../models/CreatePad';
import { AddMessageRequest } from '../models/AddMessage';

export const fetchFromApi = async (
  path: string,
  options: any = {},
  password: string = "",
): Promise<any> => {
  try {
    // Seems we need CORS even in production due to subdomain difference.
    options.mode = 'cors'
    // Optionally add the password header.
    if (password) {
      options.headers = {
        'sd-password': password
      }
    }

    const response = await fetch(`https://api.selfdestruct.me/${path}`, options)
    if (!response.ok) {
      throw Error(await response.text())
    }
    return await response.json()
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createPad = (
  expirationInMinutes: number,
  password: string,
  requestedName: string
): Promise<any> => {
  const data: CreatePadRequest = {
    name_type: CreatePadNameType.RandomChars,
    expiration_in_minutes: expirationInMinutes
  }
  if (password) {
    data.password = password
  }
  if (requestedName) {
    data.name_type = CreatePadNameType.UserDefined
    data.requested_name = requestedName
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(data)
  }
  return fetchFromApi('pad', options)
}

export const getPad = (
  name: string,
  password: string
): Promise<any> => {
  return fetchFromApi(`pad/${name}`, {}, password)
}

export const addMessage = (
  name: string,
  message: string,
  password: string
): Promise<any> => {
  const data: AddMessageRequest = {
    message: message
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(data)
  }
  return fetchFromApi(`pad/${name}/message`, options, password)
}

export const doesPadExist = (name: string): Promise<any> => {
  return fetchFromApi(`pad/${name}/exists`)
}
