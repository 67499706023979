import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Header} from './components/Header';
import {HomePage} from './pages/HomePage';
import {PadPage} from './pages/PadPage';
import {Page404} from './pages/Page404';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/:name" component={PadPage} />
          <Route exact path="/index.html" component={HomePage} />
          <Route exact path="/" component={HomePage} />
          <Route path="/" component={Page404} />
        </Switch>
      </Router>
    );
  }
}

export default App;
