import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { copyToClipboard } from '../utils/util';

export const QrCodeButton = (props: {padLink: string}) => {
  const [showQrCode, setShowQrCode] = useState(false)
  const [isQrLoaded, setQrLoaded] = useState(false)
  const padLink = props.padLink
  const imgLink = `https://api.qrserver.com/v1/create-qr-code/?data=${padLink}&size=200x200`

  const handleClose = () => {
    setShowQrCode(false)
  }

  // Show a progress bar unitl the image is loaded.
  var progressBar = <div/>
  if (!isQrLoaded) {
    progressBar = (
      <ProgressContainer>
        <CircularProgress />
      </ProgressContainer>
    )
  }

  // TODO: Pre-load the image.
  var qrCodeDialog = <div/>
  if (showQrCode) {
    qrCodeDialog = (
      <Dialog open={showQrCode} onClose={handleClose}>
        <DialogContainer>
          <QrContainer>
            {progressBar}
            <img src={imgLink} alt="QR code" onLoad={() => setQrLoaded(true)} />
          </QrContainer>
          <Button
            variant="contained"
            size="small"
            onClick={() => copyToClipboard(padLink)}
            fullWidth
          >
            Copy link to clipboard
          </Button>
        </DialogContainer>
      </Dialog>
    )
  }

  return (
    <div>
      <Tooltip title="Get QR code">
        <IconButton onClick={() => setShowQrCode(true)}>
          <SvgIcon xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
            <path d="M21 21v3h3v-3h-1v-1h-1v1h-1zm2 1v1h-1v-1h1zm-23 2h8v-8h-8v8zm1-7h6v6h-6v-6zm20 3v-1h1v1h-1zm-19-2h4v4h-4v-4zm8-3v2h-1v-2h1zm2-8h1v1h-1v-1zm1-1h1v1h-1v-1zm1 2v-1h1v1h-1zm0-2h1v-6h-3v1h-3v1h3v1h2v3zm-1-4v-1h1v1h-1zm-7 4h-4v-4h4v4zm6 0h-1v-2h-2v-1h3v1h1v1h-1v1zm-4-6h-8v8h8v-8zm-1 7h-6v-6h6v6zm3 0h-1v-1h2v2h-1v-1zm-3 3v1h-1v-1h1zm15 6h2v3h-1v-1h-2v1h-1v-1h-1v-1h1v-1h1v1h1v-1zm-4 2h-1v1h-1v-1h-1v-1h1v-1h-2v-1h-1v1h-1v1h-2v1h-1v6h5v-1h-1v-2h-1v2h-2v-1h1v-1h-1v-1h3v-1h2v2h-1v1h1v2h1v-2h1v1h1v-1h1v-2h1v-1h-2v-1zm-1 3h-1v-1h1v1zm6-6v-2h1v2h-1zm-9 5v1h-1v-1h1zm5 3v-1h1v2h-2v-1h1zm-3-23v8h8v-8h-8zm7 7h-6v-6h6v6zm-1-1h-4v-4h4v4zm1 4h1v2h-1v1h-2v-4h1v2h1v-1zm-4 6v-3h1v3h-1zm-13-7v1h-2v1h1v1h-3v-1h1v-1h-2v1h-1v-2h6zm-1 4v-1h1v3h-1v-1h-1v1h-1v-1h-1v1h-2v-1h1v-1h4zm-4-1v1h-1v-1h1zm19-2h-1v-1h1v1zm-13 4h1v1h-1v-1zm15 2h-1v-1h1v1zm-5 1v-1h1v1h-1zm-1-1h1v-3h2v-1h1v-1h-1v-1h-2v-1h-1v1h-1v-1h-1v1h-1v-1h-1v1h-1v1h-1v-1h1v-1h-4v1h2v1h-2v1h1v2h1v-1h2v2h1v-4h1v2h3v1h-2v1h2v1zm1-5h1v1h-1v-1zm-2 1h-1v-1h1v1z"/>
          </SvgIcon>
        </IconButton>
      </Tooltip>
    {qrCodeDialog}
    </div>
  )
}

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const QrContainer = styled.div`
  height: 200px;
  margin-bottom: 10px;
`;

const DialogContainer = styled(Container)`
  width: 280px;
  padding: 20px;
`;
