import React, {useState} from 'react';
import { doesPadExist } from '../utils/fetch';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { PadExistsResponse } from '../models/PadExists';
import { TheContainer } from './TheContainer';

export const PadSearch = () => {
  const [padName, setPadName] = useState("")
  const [isSearchInProgress, setIsSearchInProgress] = useState(false)
  const [isPadFound, setIsPadFound] = useState(false)

  const handlePadNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (padName.length <= 20) {
      setPadName(event.target.value.toLowerCase())
    }
  }

  const handleSubmit = () => {
    const searchForPad = async () => {
      try {
        setIsSearchInProgress(true)
        const response: PadExistsResponse = await doesPadExist(padName)
        setIsPadFound(response.does_exist)
        if (!response.does_exist) {
          throw Error("Pad doesn't exist")
        }
      } catch (error) {
        window.alert(error.message)
        setIsSearchInProgress(false)
      }
    }
    searchForPad()
  }

  const handleKeyPress = (event: React.KeyboardEvent<any>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  if (isPadFound) {
    return <Redirect to={`/${padName}`} />
  }

  return (
    <TheContainer>
      <FormGroup>
        <TextField
          margin="dense"
          variant="outlined"
          label="Pad Name"
          value={padName}
          disabled={isSearchInProgress}
          onChange={handlePadNameChange}
          onKeyPress={handleKeyPress}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={isSearchInProgress || padName === ""}
          onClick={handleSubmit}
        >
          Find
        </Button>
      </FormGroup>
    </TheContainer>
  )
}
