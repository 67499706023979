import React, {useEffect, useState} from 'react';
import { BodyWrapper } from '../components/BodyWrapper';
import { TheContainer } from '../components/TheContainer';
import { PadSearch } from '../components/PadSearch';
import { setHomeTitle } from '../utils/title';
import { pingPageView } from '../utils/analytics';
import { createPad } from '../utils/fetch';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CreatePadResponse } from '../models/CreatePad';
import styled from 'styled-components';

export const HomePage = () => {
  const [createdPadName, setCreatedPadName] = useState<string | null>(null)
  const [isCreateInProgress, setIsCreateInProgress] = useState(false)
  const [expirationInMinutes, setExpirationInMinutes] = useState(10)
  const [password, setPassword] = useState("")
  const [customName, setCustomName] = useState("")

  useEffect(() => {
    setHomeTitle()
    pingPageView()
  }, [])

  const handleExpirationChange = (event: React.ChangeEvent<{value: unknown}>) => {
    if (typeof event.target.value == 'number') {
      setExpirationInMinutes(event.target.value)
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleCustomNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 20) {
      setCustomName(event.target.value.toLowerCase())
    }
  }

  const handleCreateSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    const sendCreatePadCall = async () => {
      try {
        setIsCreateInProgress(true)
        const response: CreatePadResponse =
          await createPad(expirationInMinutes, password, customName)
        setCreatedPadName(response.name)
      } catch (error) {
        window.alert(error.message)
        setIsCreateInProgress(false)
      }
    }
    sendCreatePadCall()
  }

  if (createdPadName) {
    return <Redirect to={"/" + createdPadName} />
  } else {
    return (
      <BodyWrapper>
        <PadSearch />
        <br/>
        <TheContainer>
          <CenteredText>
            Anonymous, secure, and self-destructing notepad.
          </CenteredText>
          <br/>
          <FormGroup>
            <InputLabel id="expiration-label">Expiration</InputLabel>
            <Select
              labelId="expiration-label"
              value={expirationInMinutes}
              onChange={handleExpirationChange}
            >
              <MenuItem value={10}>10 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={60}>1 hour</MenuItem>
              <MenuItem value={360}>6 hours</MenuItem>
              <MenuItem value={1440}>1 day</MenuItem>
            </Select>
            <TextField
              margin="dense"
              variant="outlined"
              label="Password (optional)"
              onChange={handlePasswordChange}
            />
            <TextField
              margin="dense"
              variant="outlined"
              label="Custom name (optional)"
              value={customName}
              onChange={handleCustomNameChange}
            />
            <br/>
            <Button
              variant="contained"
              color="primary"
              disabled={isCreateInProgress}
              onClick={handleCreateSubmit}
            >
              Create Pad
            </Button>
          </FormGroup>
        </TheContainer>
      </BodyWrapper>
    )
  }
}

const CenteredText = styled.p`
  width: 100%;
  text-align: center;
  font-family: "Courier New";
`;