import React, {useEffect, useState, useCallback} from 'react';
import { BodyWrapper } from '../components/BodyWrapper';
import { PasswordBox } from '../components/PasswordBox';
import { QrCodeButton } from '../components/QrCodeButton';
import { setTitle } from '../utils/title';
import { pingPageView } from '../utils/analytics';
import { getPad, addMessage } from '../utils/fetch';
import { Colors } from '../utils/colors';
import { makeStyles } from '@material-ui/core/styles';
import { copyToClipboard, getPadLink } from '../utils/util';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import styled from 'styled-components';
import { GetPadResponse, Pad, Memo, MemoType } from '../models/Pad';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import Linkify from 'react-linkify';

interface PadPageParams {
  name: string
}

const useStyles = makeStyles({
  text: {
    'font-family': 'monospace',
    'font-size': '14px',
  }
});

export const PadPage = (props: RouteComponentProps<PadPageParams>) => {
  const classes = useStyles()

  const [pad, setPad] = useState<Pad | null>(null)
  const [truePassword, setTruePassword] = useState("")
  const [padInput, setPadInput] = useState("")
  const [isNonExistent, setIsNonExistent] = useState(false)
  const [errorStr, setErrorStr] = useState<string | null>(null)
  const [appendErrorStr, setAppendErrorStr] = useState<string | null>(null)
  const [isPasswordRequired, setIsPasswordRequired] = useState(false)
  const [isAppendInProgress, setIsAppendInProgress] = useState(false)
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const padName = props.match.params.name
  const padLink = getPadLink(padName)

  // Return a redirect name if we need to redirect to a lowercase path.
  const redirectName = (): string | null => {
    const lowerName = padName.toLowerCase()
    if (padName !== lowerName) {
      return lowerName
    } else {
      return null
    }
  }

  const copy = () => {
    copyToClipboard(padLink)
    setIsLinkCopied(true)
  }

  const fillPad = useCallback(async (responseFn: () => Promise<GetPadResponse>, password: string) => {
    try {
      const padResponse: GetPadResponse = await responseFn()
      if (padResponse.error_code) {
        switch(padResponse.error_code) {
          case 1:
            // Pad does not exist.
            setIsNonExistent(true)
            break;
          case 2:
            // Wrong password.
            setIsPasswordRequired(true)
            // If password is set, this means we tried a password.
            if (password !== "") {
              window.alert("Wrong password")
            }
            break;
          case 3:
            // Reached memo limit on append.
            setAppendErrorStr(padResponse.error_message)
            break;
          default:
            throw Error(padResponse.error_message)
        }
      } else {
        setTruePassword(password)
        setPad(padResponse)
      }
    } catch (error) {
      setErrorStr((error as Error).message)
    }
  }, [])

  const loadPad = useCallback(async (password: string) => {
    const doGetPad = async (): Promise<GetPadResponse> => {
      return await getPad(padName, password)
    }
    fillPad(doGetPad, password)
  }, [padName, fillPad])

  useEffect(() => {
    setTitle(padName)
    pingPageView()
    loadPad("")
  }, [padName, loadPad])

  useEffect(() => {
    const interval = setInterval(() => loadPad(truePassword), 30000);
    return () => {
      clearInterval(interval);
    };
  }, [loadPad, truePassword])

  const handleAppend = async () => {
    setIsAppendInProgress(true)
    const doAppend = async (): Promise<GetPadResponse> => {
      return await addMessage(padName, padInput, truePassword)
    }
    fillPad(doAppend, truePassword)
    setPadInput("")
    setIsAppendInProgress(false)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPadInput(event.target.value)
  }

  if (redirectName()) {
    return <Redirect to={`/${redirectName()}`} />
  }

  var padContent = <LoadingBar />
  if (isNonExistent) {
    padContent = <CenteredText>pad has been burned</CenteredText>
  } else if (errorStr) {
    padContent = <CenteredText>{errorStr}</CenteredText>
  } else if (pad) {
    padContent = (
      <div>
        <PadBox pad={pad} />
        <TheContainer>
          <FormGroup>
            <TextField
              label="Message"
              multiline
              rows={3}
              variant="outlined"
              value={padInput}
              onChange={handleInputChange}
              InputProps={{
                className: classes.text,
              }}

            />
            <Snackbar
              open={appendErrorStr != null}
              autoHideDuration={5000}
              message={appendErrorStr}
              onClose={() => setAppendErrorStr(null)}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={isAppendInProgress || padInput === ""}
              onClick={handleAppend}
            >
              Write
            </Button>
          </FormGroup>
        </TheContainer>
        <ExpirationTicker expirationDate={moment(pad.expiration_time)} />
      </div>
    )
  } else if (isPasswordRequired) {
    padContent = <PasswordBox onSubmit={(pw: string) => loadPad(pw)} />
  }

  return (
    <BodyWrapper>
      <TheContainer>
        <CenteredTitle>
          <Tooltip
            title={isLinkCopied ? 'Copied!': 'Copy'}
            onClose={() => {
              setTimeout(() => {
                setIsLinkCopied(false)
              }, 1000)
            }}
            placement="right"
          >
            <PadNameHeader onClick={copy}>
              {padName}
            </PadNameHeader>
          </Tooltip>
          <QrCodeButton padLink={padLink} />
        </CenteredTitle>
        {padContent}
      </TheContainer>
    </BodyWrapper>
  )
}

const getTooltipEl = (memo: Memo) => {
  return (
    <div>
      {moment(memo.timestamp).format("M/D/YY, h:mm:ssa")}
      <br />
      {memo.location}
    </div>
  )
}

// Need div as Tooltip's child for it to work.
export const PadBox = (props: {pad: Pad}) => {
  return (
    <Container>
      {props.pad.memos.map((memo, i) => (
        <Tooltip
          key={i}
          placement="left"
          title={getTooltipEl(memo)}
        >
          <div>
            <MemoBox memo={memo} />
          </div>
        </Tooltip>
      ))}
    </Container>
  )
}

export const MemoBox = (props: {memo: Memo}) => {
  if (props.memo.memo_type === MemoType.Message) {
    return <MessageBox memo={props.memo} />
  } else {
    return <FileBox memo={props.memo} />
  }
}

export const MessageBox = (props: {memo: Memo}) => {
  return (
    <Linkify>
      <MessageContainer>{props.memo.message}</MessageContainer>
    </Linkify>
  )
}

export const FileBox = (props: {memo: Memo}) => {
  return (
    <Card>{props.memo.timestamp}</Card>
  )
}

export const ExpirationTicker = (props: {expirationDate: moment.Moment}) => {
  const [now, setNow] = useState(moment.now());

  useEffect(() => {
    const interval = setInterval(() => setNow(moment.now()), 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const diff = moment.duration(props.expirationDate.diff(now))
  if (diff.asSeconds() <= 0) {
    return <Redirect to='/' />
  }

  const countdownStr =
    `${zeroPad(Math.floor(diff.asHours()))}:` +
      `${zeroPad(diff.minutes())}:` +
      `${zeroPad(diff.seconds())}`
  return (
    <CenteredText>Destroy in {countdownStr}</CenteredText>
  )
}

const zeroPad = (num: number): string => {
  return String(num).padStart(2, "0")
}

const MessageContainer = styled(Card)`
  min-height: 20px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  font-family: monospace;
`;

const TheContainer = styled(Container)`
  .MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
  }
  padding: 5px;
  background-color: ${Colors.backgroundEggshell};
`;

const CenteredTitle = styled.h2`
  width: 100%;
  text-align: center;
  text-decoration: underline;
`;

const CenteredText = styled.p`
  width: 100%;
  text-align: center;
  font-family: "Courier New";
`;

const PadNameHeader = styled.span`
  cursor: pointer;
`;

const LoadingBar = styled(LinearProgress)`
  margin: 50px;
`;