import * as React from 'react';
import {BodyWrapper} from '../components/BodyWrapper';
import {setTitle} from '../utils/title';
import {pingPageView} from '../utils/analytics';

export const Page404 = () => {
  setTitle('404')
  pingPageView()
  return (
    <BodyWrapper>
      <p>You lost?</p>
    </BodyWrapper>
  )
};
