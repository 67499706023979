const appName =
  (process.env.NODE_ENV === 'production') ? 'selfdestruct' : 'selfdestruct (dev)'

export const setTitle = (detail: string) => {
  document.title = `${detail} - ${appName}`
}

export const setHomeTitle = () => {
  document.title = appName
}
